import React, { useEffect, useState } from "react";
import {
	Box,
	CssBaseline,
	Autocomplete,
	TextField,
	Stack,
	Button,
	createFilterOptions,
	Grid,
	Typography,
	createTheme,
	ThemeProvider,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	LinearProgress,
	IconButton,
	Collapse,
	Avatar,
	Badge,
	Card,
	CardHeader,
} from "@mui/material";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import * as nakamajs from "@heroiclabs/nakama-js";
import cityData from "./cities.json";
import { CheckCircle, Label } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { styled } from "@mui/material/styles";
import { Session } from "@heroiclabs/nakama-js";
import VerticalStepper from "./VerticalStepper";
import { ResultsGlobe } from "./ResultsGlobe";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { UserList } from "./UserList";
import { v4 as uuidv4 } from "uuid";
import Cookies from "universal-cookie";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	useLocation,
	useNavigate,
	useParams,
	BrowserRouter,
} from "react-router-dom";
import Profile from "./Profile";
import AuthenticatedPlayButton from "./AuthenticatedPlayButton";
import ProfileButton from "./ProfileButton";
import { GoogleLogin } from "@react-oauth/google";
import MapSelector from "./MapSelector";

class OpCodes {
	static STATE_REQUEST = 0;
	static ANSWER = 1;
}
class OpCodesServer {
	static MATCH_STATE = 1;
	static RESULT = 2;
	static END_RESULT = 3;
	static USER_STATE = 4;
	static MATCHMAKING_RESULT = 5;
	static TIMER = 6;
}

class StreamModes {
	static MATCHMAKING = 101;
}

export enum MatchStep {
	NONE,
	MAP_SELECTION,
	MATCHMAKING,
	INPUT,
	WAITING,
	RESULT,
	END,
	PROFILE,
}

export type NakamaState = {
	isConnected: boolean;
	authCompleted: boolean;
	clientUserId: string;
	clientProfile: UserProfile;
	data: { [optionValue: string]: any };
	players: { [userId: string]: User };
	matchStep: MatchStep;
	inputOpen: boolean;
	inputValue: string;
	inputFocused: boolean;
	inputState: string;
	roomId: string;
	authId: string;
	result: RoundResult;
	countdown: number;
	endResult: EndResult;
	matchState: MatchState;
	selectedMap: number;
	profileId?: string;
	matchIdParam?: string;
};

interface MatchState {
	maxHp?: number;
	prompt: Prompt;
	timerState?: TimerState;
}

interface TimerState {
	timer: number;
	timerMax: number;
}

interface Prompt {
	text: string;
	image?: string;
	multiplier: number;
	country: Array<string>;
}

export interface RoundResult {
	distances: Array<Distance>;
	winnerId: string;
	target: number;
	players?: Array<User>;
	targetCity?: City;
	city?: City;
}

export interface EndResult {
	rounds: Array<RoundResult>;
	ranking: Array<User>;
	winnerId: string;
}

export interface Distance {
	userId: string;
	distance: number;
	diff: number;
	city: City;
	damage: number;
	newHp: number;
}

interface City {
	lat: number;
	lng: number;
	city: string;
	country: string;
	population: string;
}

export interface User {
	userId: string;
	profile: UserProfile;
	matchState?: UserState;
	hp: number;
}

export interface UserProfile {
	id: string;
	username: string;
	emoji: string;
	avatarUrl: string;
	location: string;
	level: number;
	totalXp: number;
	totalMatchesPlayed: number;
	totalMatchesWon: number;
	lastMatches?: Array<Match>;
}

export interface Match {
	id: string;
	timestamp: number;
	result: EndResult;
}

interface UserState {
	hp: number;
	connected: boolean;
	inputSubmitted: boolean;
}

export interface Arc {
	startLat: number;
	startLng: number;
	endLat: number;
	endLng: number;
	color: Array<string>;
}

export interface Label {
	lat: number;
	lng: number;
	size: number;
	color: Array<string>;
	round: RoundResult;
	/*cityName: string;
	distance: number;
	target: number;
	username: string;
	avatarUrl: string;*/
	pos?: DOMRect;
}

interface MatchmakingResult {
	players: Array<User>;
	matchId?: string;
}

interface AuthPayload {
	username?: string;
	uuid?: string;
}

export const COUNTRY_WORLD = "World";

class Nakama extends React.Component<{}, NakamaState> {
	client = new nakamajs.Client("defaultkey", "megara.app", "7350", true);
	socket = this.client.createSocket(true);
	initialized = false;
	session?: Session;
	matchId = "";
	matchmakingTimer?: NodeJS.Timer;

	constructor(props: any) {
		super(props);
		this.state = {
			isConnected: false,
			authCompleted: false,
			clientUserId: "",
			clientProfile: {
				id: "",
				username: "",
				emoji: "",
				avatarUrl: "",
				location: "",
				level: 0,
				totalXp: 0,
				totalMatchesPlayed: 0,
				totalMatchesWon: 0,
			},
			players: {},
			data: {},
			matchStep: MatchStep.NONE,
			inputOpen: false,
			inputValue: "",
			inputFocused: false,
			inputState: "",
			roomId: "",
			authId: "",
			result: { distances: [], winnerId: "", target: -1 },
			selectedMap: 4,
			countdown: 0,
			endResult: {
				rounds: [
					{
						distances: [
							{
								userId: "test",
								distance: 2134.23,
								diff: 315.77,
								city: {
									lat: 19.017,
									lng: 72.857,
									city: "Mumbai",
									country: "India",
									population: "0",
								},
								damage: 200,
								newHp: 5240,
							},
							{
								userId: "test2",
								distance: 1134.23,
								diff: 1315.77,
								city: {
									lat: 29.645,
									lng: 91.1,
									city: "Lhasa",
									country: "China",
									population: "0",
								},
								damage: 200,
								newHp: 5240,
							},
						],
						winnerId: "test",
						target: 2450,
						targetCity: {
							lat: 29.645,
							lng: 91.1,
							city: "Lhasa",
							country: "China",
							population: "0",
						},
						city: {
							lat: 35.685,
							lng: 139.7514,
							city: "Tokyo",
							country: "Japan",
							population: "0",
						},
					},
					{
						distances: [
							{
								userId: "test",
								distance: 2134.23,
								diff: 315.77,
								city: {
									lat: 29.645,
									lng: 91.1,
									city: "Lhasa",
									country: "China",
									population: "0",
								},
								damage: 200,
								newHp: 5240,
							},
							{
								userId: "test2",
								distance: 1134.23,
								diff: 1315.77,
								city: {
									lat: 39.645,
									lng: 191.1,
									city: "Nowhere",
									country: "Somewhere?",
									population: "0",
								},
								damage: 200,
								newHp: 5240,
							},
						],
						winnerId: "test",
						target: 1200,
						targetCity: {
							lat: 29.645,
							lng: 91.1,
							city: "Lhasa",
							country: "China",
							population: "0",
						},
						city: {
							lat: 19.017,
							lng: 72.857,
							city: "Mumbai",
							country: "India",
							population: "0",
						},
					},
					{
						distances: [
							{
								userId: "test",
								distance: 2134.23,
								diff: 315.77,
								city: {
									city: "Groningen",
									lat: 53.2204,
									lng: 6.58,
									country: "Netherlands",
									population: "0",
								},
								damage: 200,
								newHp: 5240,
							},
							{
								userId: "test2",
								distance: 1134.23,
								diff: 1315.77,
								city: {
									lat: 39.645,
									lng: 191.1,
									city: "Nowhere",
									country: "Somewhere?",
									population: "0",
								},
								damage: 200,
								newHp: 5240,
							},
						],
						winnerId: "test",
						target: 1200,
						targetCity: {
							lat: 29.645,
							lng: 91.1,
							city: "Lhasa",
							country: "China",
							population: "0",
						},
						city: {
							lat: 29.645,
							lng: 91.1,
							city: "Lhasa",
							country: "China",
							population: "0",
						},
					},
					{
						distances: [
							{
								userId: "test",
								distance: 2134.23,
								diff: 315.77,
								city: {
									city: "Split",
									lat: 43.5204,
									lng: 16.47,
									country: "Croatia",
									population: "0",
								},
								damage: 200,
								newHp: 5240,
							},
							{
								userId: "test2",
								distance: 1134.23,
								diff: 1315.77,
								city: {
									lat: 39.645,
									lng: 191.1,
									city: "Nowhere",
									country: "Somewhere?",
									population: "0",
								},
								damage: 200,
								newHp: 5240,
							},
						],
						winnerId: "test",
						target: 1200,
						targetCity: {
							lat: 29.645,
							lng: 91.1,
							city: "Lhasa",
							country: "China",
							population: "0",
						},
						city: {
							city: "Groningen",
							lat: 53.2204,
							lng: 6.58,
							country: "Netherlands",
							population: "0",
						},
					},
				],
				ranking: [],
				winnerId: "",
			},
			matchState: {
				maxHp: undefined,
				prompt: { text: "", multiplier: 1.75, country: [COUNTRY_WORLD] },
			},
			matchIdParam: props.params?.matchId,
		};
		this.initialize = this.initialize.bind(this);
		this.authenticate = this.authenticate.bind(this);
		this.authenticateCustom = this.authenticateCustom.bind(this);
		this.authenticateGoogle = this.authenticateGoogle.bind(this);
		this.initializeCityData = this.initializeCityData.bind(this);
		this.setRoomId = this.setRoomId.bind(this);
		this.authenticate = this.authenticate.bind(this);
		this.onMatchPresence = this.onMatchPresence.bind(this);
		this.onMatchData = this.onMatchData.bind(this);
		this.onStreamData = this.onStreamData.bind(this);
		this.joinMatchmaking = this.joinMatchmaking.bind(this);
		this.joinMatchmadeMatch = this.joinMatchmadeMatch.bind(this);
		this.submitAnswer = this.submitAnswer.bind(this);
		//this.setMaxHp = this.setMaxHp.bind(this);
		this.applyRoundResult = this.applyRoundResult.bind(this);
		this.getClientUserInfo = this.getClientUserInfo.bind(this);
		//this.resetUserStates = this.resetUserStates.bind(this);
		this.handleUsername = this.handleUsername.bind(this);
		this.handleRoomId = this.handleRoomId.bind(this);
		this.handlePlayPress = this.handlePlayPress.bind(this);
		this.checkForMatchResult = this.checkForMatchResult.bind(this);
		this.googleAuthResponse = this.googleAuthResponse.bind(this);
		this.setSelectedMap = this.setSelectedMap.bind(this);

		console.log("PROPS: ", props);
	}

	async googleAuthResponse(response: any) {
		console.log(response);
		const jwtSplit = response.credential.split(".");
		const data = JSON.parse(atob(jwtSplit[1]));
		const username = data.given_name;
		console.log(data);
		this.authenticateGoogle(response.credential, username);
	}

	googleAuthError() {
		console.log("Error");
	}

	async componentDidMount() {
		if (!this.initialized) {
			this.initialize();
			this.initialized = true;
		}
	}

	async initialize() {
		this.initializeCityData();

		const cookies = new Cookies();
		let privateKey = cookies.get("cityGuessrPrivateKey");
		console.log("Private Key: ", privateKey);
		console.log(this.session);
		if (privateKey) {
			await this.authenticateCustom(privateKey);
		}
		this.setState({ authCompleted: true });

		this.checkForMatchResult();
	}

	async checkForMatchResult() {
		if (this.state.matchIdParam && this.session) {
			var payload = { matchId: this.state.matchIdParam };
			var rpcResponse = await this.client.rpc(
				this.session,
				"get_match_result",
				payload
			);
			var match: Match = rpcResponse.payload as Match;
			console.log("MATCH RPC RESPONSE: ", match);
			if (match.id) {
				this.setState({ endResult: match.result, matchStep: MatchStep.END });
			} else {
				window.history.replaceState(null, "cityguessr", "/");
			}
		}
	}

	async initializeCityData(country: Array<string> = [COUNTRY_WORLD]) {
		let index = 0;
		cityData.forEach((city) => {
			if (
				country.indexOf(COUNTRY_WORLD) != -1 ||
				country.indexOf(city.country) != -1
			) {
				let optionValue = `${city.city} (${city.country})`;
				Object.keys(city).forEach((key) => {
					this.state.data[optionValue] = index;
				});
				index += 1;
			}
		});
		console.log("CITYDATA: ", this.state.data);
		this.setState({ data: this.state.data });
	}

	async authenticateCustom(privateKey: string | undefined = undefined) {
		console.log("CLIENT: ", this.client);

		console.log("UUID2: ", privateKey);
		let payload: AuthPayload = {
			uuid: privateKey ? privateKey : undefined,
			username: privateKey ? undefined : this.state.authId,
		};

		this.session = await this.client.authenticateCustom(
			JSON.stringify(payload),
			true
		);
		this.authenticate();
		if (!privateKey) {
			const cookies = new Cookies();
			let sessionVars: { [key: string]: any } | undefined = this.session.vars;
			console.log("SESSION VARS: ", sessionVars);
			cookies.set("cityGuessrPrivateKey", sessionVars?.["privateKey"], {
				path: "/",
			});
			console.log("Cookie: ", cookies.get("cityGuessrPrivateKey"));
		}
	}

	async authenticateGoogle(jwt: string, username: string) {
		this.session = await this.client.authenticateGoogle(jwt, true, username);
		this.authenticate();
	}

	async authenticate() {
		if (!this.session) return;
		this.socket.onmatchpresence = this.onMatchPresence;
		this.socket.onmatchdata = this.onMatchData;
		this.socket.onstreamdata = this.onStreamData;

		this.setState({
			clientUserId: this.session.user_id ?? "",
			profileId: this.session.user_id ?? "",
		});
		console.log("SESSION: ", this.session);
		let result = await this.socket.connect(this.session, true);
		console.log("SOCKET RESULT: ", result);

		let clientPlayer = await this.getClientUserInfo();
		if (clientPlayer) {
			this.state.players[this.state.clientUserId] = clientPlayer;
			console.log("PLAYERS: ", this.state.players);
			this.setState({ players: this.state.players });
		}
		const cookies = new Cookies();
		cookies.set("sessionToken", this.session.token, {
			path: "/",
		});
		cookies.set("refreshToken", this.session.refresh_token, {
			path: "/",
		});
	}

	async joinMatchmaking() {
		var rpcResponse = await this.socket.rpc("get_matchmaking_match_id");
		var matchmakingMatchId = JSON.parse(rpcResponse.payload ?? "");
		console.log("RPC RESPONSE: ", rpcResponse);
		var matchmakingMatch = await this.socket.joinMatch(matchmakingMatchId);
		console.log("MATCH: ", matchmakingMatch);

		/*matchmakingMatch.presences?.forEach((presence: any) => {
			this.state.players[presence.userId] = presence;
			this.setAvatarForUser(presence.userId);
		});*/
		this.setState({
			players: this.state.players,
			matchStep: MatchStep.MATCHMAKING,
		});

		return;

		//var rpcResponse = await this.socket.rpc("get_match_id_for_room", roomId);
		//console.log("RPC RESPONSE: ", rpcResponse);
		//this.matchId = rpcResponse.payload ?? "";
	}

	async joinMatchmadeMatch(matchId: string) {
		this.matchId = matchId;
		var match = await this.socket.joinMatch(matchId);
		console.log("MATCH: ", match);

		this.setRoomId(match.label);

		console.log("REQUESTING STATE...");
		let payload = {};
		await this.socket.sendMatchState(
			this.matchId,
			OpCodes.STATE_REQUEST,
			JSON.stringify(payload)
		);

		this.setState({ isConnected: true });
		this.setState({ endResult: { rounds: [], winnerId: "", ranking: [] } });
	}

	async onStreamData(streamData: any) {
		switch (streamData.stream.mode) {
			case StreamModes.MATCHMAKING:
				let matchmakingResult: MatchmakingResult = JSON.parse(streamData.data);
				console.log("MATCHMAKING DATA: ", matchmakingResult);
				break;
		}
	}

	setRoomId(roomId: string | undefined) {
		window.history.replaceState(null, "cityguessr", "/" + roomId ?? "");
	}

	async submitAnswer() {
		console.log("SUBMITTING ANSWER: ", this.state.data[this.state.inputValue]);
		let payload = {
			userId: "placeholder",
			data: { cityIndex: this.state.data[this.state.inputValue] },
		};

		this.setState({
			inputState: this.state.inputState + ".",
			inputValue: "",
		});
		console.log("PAYLOAD: ", payload);
		await this.socket.sendMatchState(
			this.matchId,
			OpCodes.ANSWER,
			JSON.stringify(payload)
		);
		this.setState({
			matchStep: MatchStep.WAITING,
		});
	}

	async onMatchPresence(matchPresenceEvent: any) {
		return;
		/*console.log("PRESENCE: ", matchPresenceEvent);
		// For each player that has joined in this event...
		matchPresenceEvent.joins?.forEach(async (presence: Presence) => {
			this.state.players[presence.userId] = presence;
			this.state.players[presence.userId].hp = this.state.matchState.maxHp;
			this.state.players[presence.userId].avatarUrl =
				await this.getAvatarForUser(presence.userId);
		});

		// For each player that has left in this event...
		matchPresenceEvent.leaves?.forEach((presence: Presence) => {
			delete this.state.players[presence.userId];
		});
		this.setState({ players: this.state.players });
		console.log("PLAYERS: ", this.state.players);*/
	}

	async getClientUserInfo() {
		if (this.session) {
			let userId = this.session.user_id ?? "";
			console.log("Requesting user data: ", userId);

			var rpcResponse = await this.socket.rpc(
				"get_user_profile",
				JSON.stringify({
					userId: userId,
					withMatches: false,
				})
			);
			let clientProfile: UserProfile = JSON.parse(rpcResponse.payload ?? "");

			console.log("Got user data: ", clientProfile);

			let clientPlayer: User = {
				userId: userId,
				profile: clientProfile,
				hp: this.state.matchState.maxHp ?? 0,
			};
			this.setState({
				clientProfile: clientProfile,
			});
			return clientPlayer;
		}
		return undefined;
	}

	/*setMaxHp(maxHp: number) {
		Object.keys(this.state.players).forEach((userId) => {
			let player = this.state.players[userId];
			player.matchState?.hp = player.hp !== undefined ? player.hp : maxHp;
		});
		this.setState({ players: this.state.players });
	}*/

	async onMatchData(matchState: any) {
		console.log("STATE RAW: ", matchState);
		var stringData = new TextDecoder().decode(matchState.data);
		var state = JSON.parse(stringData);

		switch (matchState.op_code) {
			case OpCodesServer.MATCH_STATE:
				console.log("STATE MATCH: ", state);
				let matchState: MatchState = state;
				this.setState({
					matchState: matchState,
					matchStep: MatchStep.INPUT,
				});
				this.initializeCityData(matchState.prompt.country);
				//this.setMaxHp(matchState.maxHp ?? 0);
				//this.resetUserStates();
				break;
			case OpCodesServer.RESULT:
				console.log("STATE RESULT: ", state);
				let roundResult: RoundResult = state;
				this.applyRoundResult(roundResult);
				break;
			case OpCodesServer.END_RESULT:
				console.log("STATE END: ", state);
				let endResult: EndResult = state;
				let curResult = endResult.rounds[endResult.rounds.length - 1];
				console.log("CUR RESULT: ", curResult);

				this.setState({
					endResult: endResult,
				});
				this.applyRoundResult(curResult);
				await new Promise((f) =>
					setTimeout(() => {
						console.log("SETTING STATE END!");
						this.setState({
							matchStep: MatchStep.END,
						});
					}, (getRoundActiveUsers(curResult) + 1) * 4000 + 1000)
				);
				break;
			case OpCodesServer.USER_STATE:
				console.log("STATE END: ", state);
				let userArray: Array<User> = state;
				let matchPlayers: { [userId: string]: User } = {};

				userArray.forEach((user) => {
					matchPlayers[user.userId] = user;
				});
				this.setState({
					players: matchPlayers,
				});
				break;
			case OpCodesServer.MATCHMAKING_RESULT:
				console.log("STATE MATCHMAKING: ", state);
				let matchmakingResult: MatchmakingResult = state;
				let players: { [userId: string]: any } = {};
				matchmakingResult.players.forEach(async (presence: any) => {
					players[presence.userId] = presence;
					/*players[presence.userId].avatarUrl = await this.getAvatarForUser(
						presence.userId
					);*/
				});
				console.log("PLAYERS: ", players);
				this.setState({
					players: players,
				});
				if (matchmakingResult.matchId) {
					this.joinMatchmadeMatch(matchmakingResult.matchId);
				}
				break;
			case OpCodesServer.TIMER:
				console.log("STATE TIMER: ", state);
				let timerState: TimerState = state;
				if (
					this.state.matchStep == MatchStep.INPUT ||
					this.state.matchStep == MatchStep.WAITING
				) {
					this.state.matchState.timerState = timerState;
					this.setState({
						matchState: this.state.matchState,
					});
				}
				break;
			default:
				console.log("Unsupported op code");
				break;
		}
	}

	/*resetUserStates() {
		Object.keys(this.state.players).forEach((userId) => {
			this.state.players[userId].inputSubmitted = false;
		});
		this.setState({
			players: this.state.players,
		});
	}*/

	applyRoundResult(roundResult: RoundResult) {
		roundResult.distances.forEach((distance) => {
			this.state.players[distance.userId].hp =
				distance.newHp !== undefined
					? distance.newHp
					: this.state.players[distance.userId].hp;
		});
		let players: { [userId: string]: any } = {};
		if (roundResult.players) {
			roundResult.players.forEach(async (presence: any) => {
				players[presence.userId] = presence;
			});
		}
		this.setState({
			players: players,
			result: roundResult,
			matchStep: MatchStep.RESULT,
		});
	}

	handleUsername(username: string) {
		console.log(username);
		this.setState({ authId: username });
	}
	handleRoomId(roomId: string) {
		console.log(roomId);
		this.setState({ roomId: roomId });
	}
	async handlePlayPress() {
		console.log("PLAY PRESSED: ", this.session);
		if (!this.session) {
			await this.authenticate();
		}
		//this.setState({
		//	matchStep: MatchStep.MAP_SELECTION,
		//});
		this.joinMatchmaking();
	}

	setSelectedMap(map: number) {
		this.setState({ selectedMap: map });
	}

	render() {
		const filterOptions = createFilterOptions({
			matchFrom: "any",
			limit: 3,
		});
		const backimg =
			"https://images.unsplash.com/photo-1520052205864-92d242b3a76b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2938&q=80";
		const theme = createTheme({
			palette: {
				mode: "dark",
				primary: {
					main: "#e91e63",
					light: "#ED5D8E",
					dark: "#E73873",
				},
				secondary: { main: "#ffffff", contrastText: "#fff" },
			},
			transitions: {
				duration: {
					shortest: 150,
					shorter: 200,
					short: 250,
					// most basic recommended timing
					standard: 300,
					// this is to be used in complex animations
					complex: 375,
					// recommended when something is entering screen
					enteringScreen: 225,
					// recommended when something is leaving screen
					leavingScreen: 195,
				},
			},
		});

		return (
			<div>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<Grid
						container
						textAlign={"center"}
						direction="column"
						alignItems="stretch"
						justifyContent="center"
						style={{
							backgroundImage: `url(${
								this.state.matchState.prompt.image ?? backimg
							})`,
							backgroundColor:
								this.state.matchStep == MatchStep.NONE
									? "rgba(0,0,0,0.0)"
									: "rgba(0,0,0,0.4)",
							backgroundBlendMode: "multiply",
							backgroundSize: "cover",
							backgroundPosition: "center",
							minHeight: "100vh",
							transition: "all 200ms",
						}}
					>
						<Box
							sx={{
								position: "absolute",
								width: `${
									this.state.matchStep == MatchStep.INPUT ||
									this.state.matchStep == MatchStep.WAITING
										? (1.0 -
												(this.state.matchState.timerState?.timer ?? 0) /
													(this.state.matchState.timerState?.timerMax ?? 0)) *
										  100.0
										: 100.0
								}%`,
								height: "100%",
								borderTopStyle: "solid",
								borderBottomStyle: "solid",
								borderWidth:
									this.state.matchStep == MatchStep.INPUT ||
									this.state.matchStep == MatchStep.WAITING
										? `${
												2 +
												Math.pow(
													1.0 -
														(this.state.matchState.timerState?.timer ?? 0) /
															(this.state.matchState.timerState?.timerMax ?? 0),
													3
												) *
													6
										  }px`
										: "0px",
								borderColor: "#e91e63",
								boxSizing: "border-box",
								transition: "all 1000ms",
							}}
						></Box>
						<img
							src={
								this.state.matchStep == MatchStep.NONE
									? "https://devuse.s3.eu-central-1.amazonaws.com/cityguessr_logo.png"
									: "https://devuse.s3.eu-central-1.amazonaws.com/cityguessr_logo_light.png"
							}
							style={{
								position: "absolute",
								top: this.state.matchStep == MatchStep.NONE ? "10%" : "auto",
								bottom:
									this.state.matchStep == MatchStep.NONE ? "auto" : "10px",
								left: this.state.matchStep == MatchStep.NONE ? "50%" : "20px",
								maxWidth:
									this.state.matchStep == MatchStep.NONE ? "40%" : "125px",
								maxHeight: "30%",
								transform:
									this.state.matchStep == MatchStep.NONE
										? "translate(-50%, 0%)"
										: "",
							}}
						></img>
						<ThemeProvider theme={lightStep2}>
							<Paper
								elevation={16}
								sx={{
									bgcolor: "primary.main",
									position: "absolute",
									bottom: "50px",
									padding: "2px",
									width: "auto",
									minWidth: "40px",
									height: "40px",
									left: "50%",
									transform: `translate(-50%, -50%)`,
									borderRadius: "50px",
									alignItems: "center",
									justifyContent: "center",
									display: "flex",
									opacity:
										this.state.matchStep == MatchStep.INPUT ||
										this.state.matchStep == MatchStep.RESULT ||
										this.state.matchStep == MatchStep.WAITING
											? 1.0
											: 0.0,
									transition: "all 500ms ease-out",
									transformOrigin: "center center",
								}}
							>
								<ThemeProvider theme={lightStep3}>
									<Paper
										sx={{
											bgcolor: "primary.main",
											width: "100%",
											height: "100%",
											minWidth: "40px",
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
											borderRadius: "50px",
											padding: "2px",
											transition: "all 500ms",
										}}
									>
										<ThemeProvider theme={lightTheme}>
											<Paper
												sx={{
													width: "100%",
													height: "100%",
													minWidth: "40px",
													alignItems: "center",
													justifyContent: "center",
													display: "flex",
													borderRadius: "50px",
													paddingLeft: "5px",
													paddingRight: "5px",
													transition: "all 500ms",
												}}
											>
												<Typography variant="h6" color="primary">
													{this.state.matchState.prompt.multiplier + "x"}
												</Typography>
											</Paper>
										</ThemeProvider>
									</Paper>
								</ThemeProvider>
							</Paper>
						</ThemeProvider>
						{(this.state.matchStep == MatchStep.NONE ||
							this.state.matchStep == MatchStep.MAP_SELECTION ||
							this.state.matchStep == MatchStep.INPUT ||
							this.state.matchStep == MatchStep.RESULT ||
							this.state.matchStep == MatchStep.WAITING ||
							this.state.matchStep == MatchStep.PROFILE ||
							this.state.matchStep == MatchStep.MATCHMAKING) && (
							<TransitionGroup
								transitionName="users"
								transitionEnterTimeout={500}
								transitionLeaveTimeout={500}
								childFactory={(child) => React.cloneElement(child)}
							>
								<UserList
									state={this.state}
									onPlayPress={this.handlePlayPress}
								></UserList>

								<MapSelector
									matchStep={this.state.matchStep}
									onSelectionChanged={this.setSelectedMap}
								></MapSelector>
							</TransitionGroup>
						)}
						{this.state.matchStep == MatchStep.MATCHMAKING && (
							<TransitionGroup
								transitionName="users"
								transitionEnterTimeout={500}
								transitionLeaveTimeout={500}
								childFactory={(child) => React.cloneElement(child)}
							>
								<CSSTransition
									key={"title"}
									classNames="abs"
									timeout={{ enter: 500, exit: 500 }}
									appear={true}
									mountOnEnter={true}
								>
									<Typography
										variant="h4"
										sx={{
											position: "absolute",
											top: "100px",
											left: "50%",
											transform: "translate(-50%,0)",
										}}
									>
										Searching for a match...
									</Typography>
								</CSSTransition>
							</TransitionGroup>
						)}

						<Grid item xs={1}></Grid>
						<Grid
							alignItems="center"
							justifyContent="center"
							spacing={100}
							item
							xs={3}
						>
							<Stack
								sx={{
									paddingTop:
										this.state.matchStep == MatchStep.NONE ? "200px" : "",
								}}
								alignItems="center"
								spacing={5}
							>
								{this.state.matchStep == MatchStep.NONE &&
									!this.state.isConnected &&
									this.state.authCompleted &&
									!this.session && (
										<div style={{ zIndex: 1000 }}>
											<GoogleLogin
												onSuccess={this.googleAuthResponse}
												onError={this.googleAuthError}
												useOneTap
												auto_select
											/>
										</div>
										/*<VerticalStepper
											handleUsername={this.handleUsername}
											handleRoomId={this.handleRoomId}
											handlePlayPress={this.handlePlayPress}
										></VerticalStepper>*/
									)}
								{this.state.matchStep == MatchStep.RESULT && false && (
									<TransitionGroup
										transitionName="users"
										transitionEnterTimeout={500}
										transitionLeaveTimeout={500}
										childFactory={(child) => React.cloneElement(child)}
									>
										<CSSTransition
											key={"input"}
											classNames="users"
											timeout={{ enter: 500, exit: 500 }}
											appear={true}
											mountOnEnter={true}
										>
											<Stack
												justifyContent="center"
												alignItems="center"
												spacing={2}
												sx={{ width: 1 }}
											>
												<Typography variant="h3" color="secondary">
													{this.state.players[this.state.result.winnerId]
														.profile.username + " wins!"}
												</Typography>

												<ThemeProvider theme={lightTheme}>
													<TableContainer
														sx={{
															maxWidth: 750,
															maxHeight: 450,
															display: "none",
														}}
														component={Paper}
													>
														<Table aria-label="simple table">
															<TableHead>
																<TableRow>
																	<TableCell>User</TableCell>
																	<TableCell align="right">{`City (Origin: ${this.state.result.city?.city})`}</TableCell>
																	<TableCell align="right">{`Distance (Target: ${this.state.result.target}km)`}</TableCell>
																	<TableCell align="right">{`HP`}</TableCell>
																</TableRow>
															</TableHead>
															<TableBody>
																{this.state.result.distances.map(
																	(row) =>
																		row.distance != -1 && (
																			<TableRow
																				key={row.userId}
																				sx={{
																					"&:last-child td, &:last-child th": {
																						border: 0,
																					},
																				}}
																			>
																				<TableCell component="th" scope="row">
																					{
																						this.state.players[row.userId]
																							.profile.username
																					}
																				</TableCell>
																				<TableCell align="right">
																					{`${row.city?.city} (${row.city?.country})`}
																				</TableCell>
																				<TableCell align="right">
																					{`${row.distance} (${row.diff}km away)`}
																				</TableCell>
																				<TableCell
																					align="right"
																					style={
																						row.damage
																							? { color: "IndianRed" }
																							: { color: "#66bb6a" }
																					}
																				>
																					{row.damage
																						? `-${row.damage}`
																						: "+/- 0"}
																				</TableCell>
																			</TableRow>
																		)
																)}
															</TableBody>
														</Table>
													</TableContainer>
												</ThemeProvider>
												{this.state.matchStep == MatchStep.RESULT && (
													<Typography variant="h6" color="secondary">
														{"Next round will start in a few seconds..."}
													</Typography>
												)}
											</Stack>
										</CSSTransition>
									</TransitionGroup>
								)}

								{this.state.matchStep == MatchStep.END && (
									<ThemeProvider key="result" theme={theme}>
										<CssBaseline />
										<Box
											sx={{
												position: "absolute",
												width: "100%",
												height: "100vh",
												left: 0,
												top: 0,
												//backgroundImage: `url(https://devuse.s3.eu-central-1.amazonaws.com/background_results.png)`,
												backgroundSize: "cover",
												backgroundPosition: "center",
												zIndex: 0,
											}}
										>
											{/* <ThemeProvider theme={lightStep3}>
																<Paper
																	sx={{
																		bgcolor: "primary.main",
																		position: "absolute",
																		width: "550px",
																		height: "550px",
																		left: "50%",
																		top: "50%",
																		transform: "translate(-50%, -50%)",
																		borderRadius: "500px",
																	}}
																></Paper>
															</ThemeProvider> 
															<ThemeProvider theme={lightStep2}>
																<Paper
																	sx={{
																		bgcolor: "primary.main",
																		position: "absolute",
																		width: "540px",
																		height: "540px",
																		left: "50%",
																		top: "50%",
																		transform: "translate(-50%, -50%)",
																		borderRadius: "500px",
																	}}
																></Paper>
															</ThemeProvider>
															<ThemeProvider theme={lightTheme}>
																<Paper
																	sx={{
																		position: "absolute",
																		width: "530px",
																		height: "530px",
																		left: "50%",
																		top: "50%",
																		transform: "translate(-50%, -50%)",
																		borderRadius: "500px",
																	}}
																></Paper>
															</ThemeProvider>*/}

											<TransitionGroup
												transitionName="users"
												transitionEnterTimeout={500}
												transitionLeaveTimeout={500}
												childFactory={(child) => React.cloneElement(child)}
											>
												<CSSTransition
													key={"result"}
													classNames="result"
													timeout={{ enter: 50000, exit: 50000 }}
													appear={true}
													mountOnEnter={true}
												>
													<ResultsGlobe
														endResult={this.state.endResult}
														players={this.state.players}
													></ResultsGlobe>
												</CSSTransition>
											</TransitionGroup>
										</Box>
									</ThemeProvider>
								)}

								{(this.state.matchStep == MatchStep.INPUT ||
									this.state.matchStep == MatchStep.WAITING ||
									this.state.matchStep == MatchStep.RESULT) && (
									<TransitionGroup
										transitionName="users"
										transitionEnterTimeout={500}
										transitionLeaveTimeout={500}
										childFactory={(child) => React.cloneElement(child)}
									>
										<CSSTransition
											key={"input"}
											classNames="users"
											timeout={{ enter: 500, exit: 500 }}
											appear={true}
											mountOnEnter={true}
										>
											<Stack
												justifyContent="center"
												alignItems="center"
												spacing={10}
												sx={{ width: 1 }}
											>
												<Typography variant="h3" color="secondary">
													{this.state.matchState.prompt.text}
												</Typography>
												{this.state.matchStep == MatchStep.WAITING ? (
													<Typography variant="h6" color="secondary">
														{"Waiting for other players..."}
													</Typography>
												) : this.state.matchStep == MatchStep.RESULT ||
												  this.state.players[this.state.clientUserId].hp ==
														0 ? (
													<div></div>
												) : (
													<Stack
														direction="row"
														justifyContent="center"
														spacing={2}
														sx={{ width: 1 }}
													>
														<Autocomplete
															id="maininput"
															popupIcon={""}
															sx={{
																minWidth:
																	this.state.inputFocused ||
																	this.state.inputValue != ""
																		? "450px"
																		: "400px",
																backdropFilter:
																	this.state.inputFocused ||
																	this.state.inputValue != ""
																		? "blur(10px)"
																		: "",
																transition:
																	"backdrop-filter 50ms, min-width 250ms",
															}}
															key={this.state.inputState}
															open={this.state.inputOpen}
															onFocus={() => {
																this.setState({ inputFocused: true });
															}}
															onBlur={() => {
																this.setState({ inputFocused: false });
															}}
															onOpen={() => {
																if (this.state.inputValue) {
																	this.setState({
																		inputOpen: true,
																	});
																}
															}}
															onClose={() =>
																this.setState({
																	inputOpen: false,
																})
															}
															onInputChange={(e, value, reason) => {
																this.setState({
																	inputValue: value,
																});
																if (!value) {
																	this.setState({
																		inputOpen: false,
																	});
																}
															}}
															filterOptions={filterOptions}
															options={cityData
																.sort()
																.filter(
																	(option) =>
																		this.state.matchState.prompt.country.indexOf(
																			COUNTRY_WORLD
																		) != -1 ||
																		this.state.matchState.prompt.country.indexOf(
																			option.country
																		) != -1
																)
																.map(
																	(option) =>
																		`${option.city} (${option.country})`
																)}
															renderInput={(params) => (
																<TextField {...params} label="Enter City" />
															)}
														/>
														<Button
															variant="contained"
															sx={{
																opacity:
																	this.state.data[this.state.inputValue] !==
																	undefined
																		? 1.0
																		: 0.0,
																transition: "all 500ms ease-out",
																width:
																	this.state.data[this.state.inputValue] !==
																	undefined
																		? "100px"
																		: "0px",
																padding:
																	this.state.data[this.state.inputValue] !==
																	undefined
																		? "auto"
																		: "0px",
																minWidth: "0px",
																overflow: "hidden",
															}}
															onClick={() => {
																this.submitAnswer();
															}}
														>
															Submit
														</Button>
													</Stack>
												)}
											</Stack>
										</CSSTransition>
									</TransitionGroup>
								)}
							</Stack>
						</Grid>
					</Grid>
				</ThemeProvider>
			</div>
		);
	}
}

function Row(props: { row: RoundResult; state: NakamaState }) {
	const { row, state } = props;
	const [open, setOpen] = React.useState(false);

	return (
		<React.Fragment>
			<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
					{row.city?.city}
				</TableCell>
				<TableCell align="right">
					{state.players[row.winnerId].profile.username}
				</TableCell>
				<TableCell align="right">{row.distances[0].city.city}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Table aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell>User</TableCell>
										<TableCell align="right">{`City (Origin: ${row.city?.city})`}</TableCell>
										<TableCell align="right">{`Distance (Target: ${row.target}km)`}</TableCell>
										<TableCell align="right">{`HP`}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.distances.map((distance) => (
										<TableRow key={distance.userId}>
											<TableCell component="th" scope="row">
												{state.players[distance.userId].profile.username}
											</TableCell>
											<TableCell align="right">
												{`${distance.city.city} (${distance.city.country})`}
											</TableCell>
											<TableCell align="right">
												{`${distance.distance} (${distance.diff}km away)`}
											</TableCell>
											<TableCell
												align="right"
												style={
													distance.damage
														? { color: "IndianRed" }
														: { color: "GreenYellow" }
												}
											>
												{distance.damage ? `-${distance.damage}` : "+/- 0"}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

export function getRoundActiveUsers(round: RoundResult) {
	let count = 0;
	round.distances.forEach((distance) => {
		if (distance.city?.city) {
			count += 1;
		}
	});
	console.log("COUNT: ", count);
	return count;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
	"& .MuiBadge-badge": {
		backgroundColor: "#44b700",
		color: "#44b700",
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "ripple 1.2s infinite ease-in-out",
			border: "1px solid currentColor",
			content: '""',
		},
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1,
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0,
		},
	},
}));

export function stringToColor(string: string = "") {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = "#";

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}

export function shadeColor(color: string, percent: number) {
	var R = parseInt(color.substring(1, 3), 16);
	var G = parseInt(color.substring(3, 5), 16);
	var B = parseInt(color.substring(5, 7), 16);

	R = Math.floor((R * (100 + percent)) / 100);
	G = Math.floor((G * (100 + percent)) / 100);
	B = Math.floor((B * (100 + percent)) / 100);

	R = R < 255 ? R : 255;
	G = G < 255 ? G : 255;
	B = B < 255 ? B : 255;

	var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
	var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
	var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

	return "#" + RR + GG + BB;
}

const badgeStyle = {
	"& .MuiBadge-badge": {
		color: "yellow",
		backgroundColor: "#686868",
	},
	/*"& .MuiBadge-badge": {
		bottom: "-18px",
		top: "auto",
		right: "50%",
		left: "50%",
		transform: "scale(1) translate(-50%, -50%)",
		backgroundColor: "#44b700",
	},*/
};

export const lightTheme = createTheme({
	palette: {
		primary: {
			main: "#e91e63",
		},
	},
	transitions: {
		duration: {
			shortest: 150,
			shorter: 200,
			short: 250,
			// most basic recommended timing
			standard: 300,
			// this is to be used in complex animations
			complex: 375,
			// recommended when something is entering screen
			enteringScreen: 225,
			// recommended when something is leaving screen
			leavingScreen: 195,
		},
	},
});

export const lightStep1 = createTheme({
	palette: {
		primary: {
			main: "#E73873",
		},
	},
});
export const lightStep2 = createTheme({
	palette: {
		primary: {
			main: "#ED5D8E",
		},
	},
});
export const lightStep3 = createTheme({
	palette: {
		primary: {
			main: "#EF96B5",
		},
	},
});
export const lightStep4 = createTheme({
	palette: {
		primary: {
			main: "#FFA0C1",
		},
	},
});

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
}

export const App = (props: any) => {
	let updatedProps = { ...props };
	updatedProps.location = useLocation();
	updatedProps.navigate = useNavigate();
	updatedProps.params = useParams();
	return <Nakama {...updatedProps}></Nakama>;
};

export const AppWithRouter = (props: any) => {
	return (
		<div>
			<Router>
				<Routes>
					<Route path="/" element={<App {...props}></App>}></Route>
					<Route
						path="/profile/:userId"
						element={<App {...props}></App>}
					></Route>
					<Route
						path="/match/:matchId"
						element={<App {...props}></App>}
					></Route>
					<Route path="*" element={<Navigate replace to="/" />} />
				</Routes>
			</Router>
		</div>
	);
};

export default AppWithRouter;
