import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { TextField, ThemeProvider } from "@mui/material";
import { act } from "react-dom/test-utils";
import { useParams } from "react-router-dom";
import { MatchStep, lightStep3 } from "./App";

interface Props {
	matchStep: MatchStep;
	onSelectionChanged: Function;
}

interface Map {
	name: string;
	imageUrl: string;
}

const maps: Map[] = [
	{
		name: "Europe",
		imageUrl:
			"https://images.unsplash.com/photo-1519677100203-a0e668c92439?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&h=1080&q=80",
	},
	{
		name: "Africa",
		imageUrl:
			"https://images.unsplash.com/photo-1489392191049-fc10c97e64b6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&h=1080&q=80",
	},
	{
		name: "Asia",
		imageUrl:
			"https://images.unsplash.com/photo-1532236395709-7d70320fec2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&h=1080&q=80",
	},
	{
		name: "America",
		imageUrl:
			"https://images.unsplash.com/photo-1483729558449-99ef09a8c325?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&h=1080&q=80",
	},
	{
		name: "World",
		imageUrl:
			"https://images.unsplash.com/photo-1569956726918-b36bd5e659b2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&h=1080&q=80",
	},
	{
		name: "USA",
		imageUrl:
			"https://images.unsplash.com/photo-1508433957232-3107f5fd5995?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&h=1080&q=80",
	},
	{
		name: "Germany",
		imageUrl:
			"https://images.unsplash.com/photo-1573803621309-2e2c10679ccd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&h=1080&q=80",
	},
	{
		name: "Japan",
		imageUrl:
			"https://images.unsplash.com/photo-1601823984263-b87b59798b70?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&h=1080&q=80",
	},
	{
		name: "China",
		imageUrl:
			"https://images.unsplash.com/photo-1567014688543-cc4abffb061a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&h=1080&q=80",
	},
];

export default function MapSelector(props: Props) {
	const [hoverIndex, setHoverIndex] = React.useState(0);
	const { userId } = useParams();

	const centralIndex = Math.floor(maps.length / 2);

	function setHover(index: number) {
		console.log(index);
		setHoverIndex(index);
		props.onSelectionChanged(index);
	}

	return (
		<ThemeProvider theme={lightStep3}>
			<Paper
				sx={{
					width: "100%",
					height: props.matchStep == MatchStep.MAP_SELECTION ? "100%" : "100%",
					opacity: props.matchStep == MatchStep.MAP_SELECTION ? 1 : 0,
					display: "flex",
					flexDirection: "row",
					position: "absolute",
					top: "0",
					transition: "all 1000ms",
					overflow: "hidden",
					zIndex: 90,
					pointerEvents: "none",
				}}
			>
				{maps.map((map, index) => (
					<Paper
						sx={{
							flexGrow:
								hoverIndex == index ? 4 : index == centralIndex ? 1.5 : 1,
							flexBasis: 0,
							flexShrink: 1,
							height: "100%",
							backgroundImage: `url('${map.imageUrl}')`,
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
							backgroundPosition: "center",
							backgroundColor:
								hoverIndex == -1
									? "rgba(0,0,0,0.1)"
									: hoverIndex == index
									? "rgba(0,0,0,0.0)"
									: "rgba(0,0,0,0.5)",
							backgroundBlendMode: "multiply",
							transition: "all 0.25s ease",
							transitionDelay: "0.05s",
							display: "inline-block",
							borderRadius: "0px",
							overflow: "hidden",
							zIndex:
								hoverIndex == index
									? 103
									: index == centralIndex
									? 102
									: index < centralIndex
									? 90 + index
									: 90 - index,
							boxShadow:
								hoverIndex == index
									? "0px 0px 50px 50px rgba(0,0,0,0.5)"
									: "0px 0px 10px 10px rgba(0,0,0,0.5)",
							cursor:
								props.matchStep == MatchStep.MAP_SELECTION
									? "pointer"
									: "default",
						}}
						onMouseOver={() => setHover(index)}
						onMouseOut={() => setHover(-1)}
					>
						<Typography
							variant="h2"
							color="primary"
							sx={{
								color:
									hoverIndex == -1
										? "rgba(255,255,255,0.5)"
										: hoverIndex == index
										? "white"
										: "rgba(255,255,255,0.2)",
								textAlign: "center",
								paddingTop: hoverIndex == index ? "50vh" : "5vh",
								fontSize: hoverIndex == index ? "3rem" : "1.5rem",
								userSelect: "none",
								transition: "all 0.25s ease",
								transitionDelay: "0.05s",
							}}
						>{`${map.name}`}</Typography>
					</Paper>
				))}
			</Paper>
		</ThemeProvider>
	);
}
