import { ThemeProvider } from "@emotion/react";
import { CheckCircle } from "@mui/icons-material";
import {
	Stack,
	Badge,
	Card,
	CardHeader,
	Avatar,
	LinearProgress,
	styled,
	Paper,
	Typography,
	Button,
	Box,
	CircularProgress,
	CircularProgressProps,
} from "@mui/material";
import {
	lightStep1,
	lightStep2,
	lightTheme,
	MatchStep,
	NakamaState,
	stringToColor,
	shadeColor,
	User,
	RoundResult,
	Distance,
	UserProfile,
	Match,
} from "./App";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { MouseEventHandler, useEffect, useState } from "react";
import { StaticReadUsage } from "three";
import { Link } from "react-router-dom";
const countryLookup = require("country-code-lookup");
const countryFlagColors = require("country-flag-colors");
const dayjs = require("dayjs");
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const partyPopper =
	"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Party%20Popper.png";

export const UserList = (props: {
	state: NakamaState;
	onPlayPress: MouseEventHandler;
}) => {
	const totalItems = 9;
	const totalGrowExpand = totalItems - 2 + 1.5 + 4;

	const shrunkPartExpand = (1 / totalGrowExpand) * 100;
	const expandedPart = (4 / totalGrowExpand) * 100;

	const absOffset =
		(4 - Math.abs(props.state.selectedMap - 4)) * shrunkPartExpand;
	const mult = props.state.selectedMap > 4 ? -1 : 1;
	const totalOffset = mult * 50;
	const adjustedOffset =
		props.state.selectedMap == 4 || props.state.selectedMap == -1
			? 0
			: mult * absOffset - totalOffset + mult * (expandedPart / 2);

	return (
		<Stack
			sx={{
				position: "absolute",
				top:
					props.state.matchStep == MatchStep.MAP_SELECTION
						? "100%"
						: props.state.matchStep == MatchStep.MATCHMAKING
						? "50%"
						: props.state.matchStep == MatchStep.NONE
						? "55%"
						: "30px",
				marginTop:
					props.state.matchStep == MatchStep.MAP_SELECTION ? "-100px" : "0",
				transform:
					props.state.matchStep == MatchStep.MATCHMAKING
						? "translate(0, -25px)"
						: "",
				transition: "all 500ms",
				width: props.state.matchStep == MatchStep.MAP_SELECTION ? 1 : 1,
				marginLeft:
					props.state.matchStep == MatchStep.MAP_SELECTION
						? `${adjustedOffset}vw`
						: "0",
				height: "200px",
				zIndex: 100,
			}}
			direction="row"
			alignItems="start"
			justifyContent="center"
		>
			{Object.keys(props.state.players)
				.sort()
				.map((userId, index) => (
					<UserLabel
						key={userId}
						userId={userId}
						state={props.state}
						result={
							props.state.matchStep == MatchStep.RESULT
								? props.state.result
								: undefined
						}
						winnerId={props.state.endResult?.winnerId}
						index={index}
						step={props.state.matchStep}
						profile={props.state.players?.[userId]?.profile}
						isProfile={
							props.state.matchStep == MatchStep.PROFILE &&
							props.state.profileId == userId
						}
						showPlayButton={
							props.state.matchStep == MatchStep.NONE &&
							userId == props.state.clientUserId
						}
						onPlayPress={props.onPlayPress}
					></UserLabel>
				))}
		</Stack>
	);
};

export const UserLabel = (props: {
	userId: string;
	state: NakamaState;
	winnerId: string;
	result: RoundResult | undefined;
	index: number;
	step: MatchStep;
	profile: UserProfile;
	isProfile: boolean;
	showPlayButton: boolean;
	onPlayPress: MouseEventHandler;
}) => {
	const [loaded, setLoaded] = useState(false);
	const [avatarLoaded, setAvatarLoaded] = useState(false);
	const [resultShown, setResultShown] = useState(false);
	const [showResult, setShowResult] = useState(false);
	const [isResult, setIsResult] = useState(false);
	const [curHp, setCurHp] = useState(6000);
	const [showXp, setShowXp] = useState(false);
	const [isClicked, setIsClicked] = useState(false);
	const [isMouseOver, setIsMouseOver] = useState(false);
	const [wasMouseOver, setWasMouseOver] = useState(false);
	const [userDistance, setUserDistance] = useState<Distance>({
		userId: "",
		distance: -1,
		diff: 0,
		city: {
			lat: 0.0,
			lng: 0.0,
			city: "Nowhere",
			country: "Somewhere?",
			population: "0",
		},
		damage: 0,
		newHp: 0,
	});

	useEffect(() => {
		const init = async () => {
			await new Promise((f) =>
				setTimeout(() => {
					if (!loaded) {
						console.log(
							"USE EFFECT LOADED: ",
							loaded,
							" - ",
							props.state.players[props.userId].profile.username
						);
						setLoaded(true);
					}
				}, 100)
			);
		};
		if (!loaded) {
			console.log(
				"USE EFFECT: ",
				loaded,
				" - ",
				props.state.players[props.userId].profile.username
			);
			init();
		}
	});

	function updateHp() {
		let hp = props.state.players[props.userId].matchState?.hp;
		if (hp != undefined && hp != curHp) {
			console.log("SETTING CUR HP: ", hp);
			setCurHp(hp ?? 6000);
		}
	}

	if (props.step == MatchStep.INPUT) {
		if (resultShown) {
			setResultShown(false);
		}
	}

	let rank = 0;
	let resultCount = 0;
	let isWinner = props.winnerId == props.userId;
	props.result?.distances.forEach((distance, index) => {
		if (distance.userId == props.userId) {
			rank = index;
			if (!resultShown) {
				if (distance.newHp != curHp) {
					setUserDistance(distance);
					new Promise((f) =>
						setTimeout(() => {
							setIsResult(false);
							setShowResult(false);
							setShowXp(false);
							updateHp();
						}, 28000)
					);
					setResultShown(true);
					setShowResult(true);
					setIsResult(true);
					setIsMouseOver(false);
					setIsClicked(false);
					setShowXp(distance.newHp == 0 || isWinner);
				}
			}
		}
		if (distance.city?.city) {
			resultCount += 1;
		}
	});
	let resultDelay = rank * 4000 + 1000;
	let lastResultDelay = (resultCount - 1) * 4000 + 1000;
	let exitDelay = props.index * 50;
	let xpDelay = isWinner ? lastResultDelay + 4000 : resultDelay + 3400;
	console.log("SHOWRESULT: ", showResult);

	let colors = [
		shadeColor(
			stringToColor(props.state.players?.[props.userId]?.profile?.username),
			-20
		),
		shadeColor(
			stringToColor(props.state.players?.[props.userId]?.profile?.username),
			-10
		),
		stringToColor(props.state.players?.[props.userId]?.profile?.username),
	];

	let countryColors = countryFlagColors.find(
		(f: { [key: string]: any }) =>
			f.name ==
			countryLookup.byIso(
				props.state.players[props.userId]?.profile.location?.length > 1
					? props.state.players?.[props.userId]?.profile.location
					: "DE"
			)?.country
	)?.colors;
	console.log("CCUSERID: ", props.userId);
	console.log(
		"CCOUNTRY: ",
		props.state.players?.[props.userId]?.profile.location
	);
	console.log("CCOLORS: ", countryColors);
	if (countryColors && countryColors.length != 0) {
		while (countryColors.length < 3) {
			countryColors.push(countryColors[0]);
		}
		//colors = countryColors;
	}

	let isProfile = (props.isProfile || isClicked) && !showResult;
	let isStats = (isMouseOver || isProfile) && !showResult;
	let showHistory = isProfile;

	return (
		<ThemeProvider theme={lightTheme}>
			<Stack
				alignItems="center"
				spacing={2}
				sx={{
					paddingLeft: loaded ? "20px" : "0px",
					paddingRight: loaded ? "20px" : "0px",
					transition: "all 500ms",
					cursor: "pointer",
				}}
			>
				<Badge
					badgeContent={<CheckCircle sx={{ width: "0.6em" }}></CheckCircle>}
					invisible={
						!props.state.players[props.userId].matchState?.inputSubmitted
					}
					color="primary"
				>
					<Card
						sx={{
							width: loaded ? 180 : "0px",
							height: isProfile ? "auto" : "auto",
							filter:
								props.state.players[props.userId].matchState?.hp == 0 ||
								!(
									props.state.players[props.userId].matchState?.connected ??
									true
								)
									? "saturate(0)"
									: "",
							transform:
								props.state.players[props.userId].matchState?.hp == 0 ||
								!(
									props.state.players[props.userId].matchState?.connected ??
									true
								)
									? "scale(0.9)"
									: "",
							transition: isProfile ? "all 800ms" : "all 800ms",
							transitionDelay: showResult ? `${resultDelay + 2500}ms` : "0ms",
						}}
					>
						<CardHeader
							sx={{
								padding: "8px",
								paddingLeft: avatarLoaded
									? showResult
										? "180px"
										: "65px"
									: "0px",
								overflow: "hidden",
								height: showResult ? "100px" : "58px",
								transition: isProfile
									? "all 0ms"
									: "padding-left 1000ms, height 500ms",
								transitionDelay: isProfile
									? "0ms"
									: showResult
									? `${resultDelay}ms, ${resultDelay + 1000}ms`
									: props.step == MatchStep.RESULT
									? `${exitDelay + 1400}ms, ${exitDelay + 800}ms`
									: `${exitDelay + 1200}ms`,
							}}
							avatar={
								<Badge
									overlap="circular"
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "right",
									}}
									variant="dot"
								>
									<Paper
										sx={{
											bgcolor: colors[0],
											borderRadius: "50%",
											padding: "22px",
											paddingLeft: "37px",
											paddingRight: "7px",
											position: "absolute",
											left: "auto",
											top: "auto",
											width: showResult ? "220px" : "110px",
											height: showResult ? "220px" : "110px",
											transform: avatarLoaded
												? showResult
													? "translate(-200px, -50%)"
													: isStats
													? "translate(20px, -50%)"
													: "translate(-100px, -50%)"
												: "translate(-200px, -50%)",
											boxShadow: "inset 0 0 5px #00000088",
											zIndex: 0,
											transition: isProfile ? "all 0ms" : "all 1000ms",
											transitionDelay: isProfile
												? "0ms"
												: showResult
												? `${resultDelay}ms`
												: props.step == MatchStep.RESULT
												? `${exitDelay + 1400}ms`
												: wasMouseOver
												? "0ms"
												: `${exitDelay + 500}ms`,
										}}
									>
										<Typography
											variant="subtitle2"
											sx={{
												color: "white",
												marginTop: "8px",
												marginLeft: "-8px",
												opacity: isStats && !showResult ? "1.0" : "0.0",
												transition: "all 1000ms",
											}}
										>
											Level
										</Typography>
										<Typography
											variant="h5"
											sx={{
												color: "white",
												marginTop: "-2px",
												marginLeft: "-8px",
												opacity: isStats && !showResult ? "1.0" : "0.0",
												transition: "all 1000ms",
											}}
										>
											{props.state.players[props.userId].profile.level + 1}
										</Typography>
									</Paper>
									<Paper
										sx={{
											bgcolor: colors[1],
											borderRadius: "50%",
											padding: "22px",
											paddingLeft: "37px",
											paddingRight: "7px",
											position: "absolute",
											left: "auto",
											top: "auto",
											width: showResult ? "140px" : "105px",
											height: showResult ? "140px" : "105px",
											transform: avatarLoaded
												? showResult
													? "translate(-160px, -50%)"
													: isStats
													? "translate(-45px, -50%)"
													: "translate(-100px, -50%)"
												: "translate(-200px, -50%)",
											boxShadow: "inset 0 0 5px #00000088",
											zIndex: 0,
											transition: isProfile ? "all 0ms" : "all 1000ms",
											transitionDelay: isProfile
												? "0ms"
												: showResult
												? `${resultDelay}ms`
												: props.step == MatchStep.RESULT
												? `${exitDelay + 1400}ms`
												: wasMouseOver
												? "0ms"
												: `${exitDelay + 800}ms`,
										}}
									>
										<Typography
											variant="subtitle2"
											sx={{
												color: "white",
												marginTop: "5px",
												marginLeft: "8px",
												opacity: isStats && !showResult ? "1.0" : "0.0",
												transition: "all 1000ms",
											}}
										>
											Wins
										</Typography>
										<Typography
											variant="h5"
											sx={{
												color: "white",
												marginTop: "-2px",
												marginLeft: "8px",
												opacity: isStats && !showResult ? "1.0" : "0.0",
												transition: "all 1000ms",
											}}
										>
											{
												props.state.players[props.userId].profile
													.totalMatchesWon
											}
										</Typography>
									</Paper>
									<Avatar
										alt={props.state.players[props.userId].profile.username}
										src={props.state.players[props.userId].profile.avatarUrl}
										sx={{
											bgcolor: colors[2],
											padding: showResult ? "12px" : "22px",
											paddingLeft: showResult ? "12px" : "37px",
											paddingRight: showResult ? "12px" : "7px",
											position: "absolute",
											left: "auto",
											top: "auto",
											width: "100px",
											height: "100px",
											transform: avatarLoaded
												? showResult
													? "translate(-140px, -50%)"
													: "translate(-100px, -50%)"
												: "translate(-200px, -50%)",
											boxShadow: "inset 0 0 5px #00000088",
											zIndex: 0,
											transition: isProfile ? "all 0ms" : "all 1000ms",
											transitionDelay: isProfile
												? "0ms"
												: showResult
												? `${resultDelay}ms`
												: props.step == MatchStep.RESULT
												? `${exitDelay + 1400}ms`
												: `${exitDelay + 1100}ms`,
										}}
										onLoad={() => {
											console.log(
												"LOADED ",
												avatarLoaded,
												" - ",
												props.state.players[props.userId].profile.username
											);
											setAvatarLoaded(true);
										}}
										onClick={() => {
											setIsClicked(!isClicked);
										}}
										onMouseOver={() => {
											setIsMouseOver(true);
											setWasMouseOver(true);
										}}
										onMouseLeave={() => {
											setIsMouseOver(false);
										}}
										onMouseEnter={() => {
											setIsMouseOver(false);
										}}
										onMouseOut={() => {
											setIsMouseOver(false);
										}}
									/>
								</Badge>
							}
							title={props.state.players[props.userId].profile.username}
							subheader={
								props.state.players[props.userId].matchState?.hp != undefined
									? curHp == 0
										? "Eliminated"
										: !props.state.players[props.userId].matchState?.connected
										? "Disconnected"
										: curHp
									: countryLookup.byIso(
											props.state.players[props.userId]?.profile.location
												?.length > 1
												? props.state.players[props.userId]?.profile.location
												: "DE"
									  )?.country
							}
							titleTypographyProps={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								maxWidth: "99%",
							}}
						></CardHeader>

						<Paper
							sx={{
								backgroundColor: "#f0b1c7",
								borderRadius: "0px",
								boxShadow: "inset 0 0 1px #00000088",
								maxWidth: "100%",
								height:
									props.step == MatchStep.INPUT ||
									props.step == MatchStep.RESULT ||
									props.step == MatchStep.WAITING
										? 6
										: 0,
								width: "100%",
								transition: "all 200ms",
								transitionDelay: "1000ms",
							}}
						>
							<Paper
								sx={{
									backgroundColor: "#e91e63",
									borderRadius: "0px",
									boxShadow: "inset 0 0 1px #00000088",
									maxWidth: "100%",
									height: 2,
									width: `${
										((props.state.players[props.userId].matchState?.hp ?? 0) /
											(props.state.matchState.maxHp ?? 0)) *
										100
									}%`,
									transition: isProfile ? "all 0ms" : "all 800ms",
									transitionDelay: isProfile
										? "0ms"
										: showResult
										? `${resultDelay + 2500}ms`
										: "0ms",
								}}
							/>
							<ThemeProvider theme={lightStep1}>
								<Paper
									sx={{
										backgroundColor: "#E73873",
										borderRadius: "0px",
										boxShadow: "inset 0 0 1px #00000088",
										maxWidth: "100%",
										height: 2,
										width: `${
											((props.state.players[props.userId].matchState?.hp ?? 0) /
												(props.state.matchState.maxHp ?? 0)) *
											100
										}%`,
										transition: isProfile ? "all 0ms" : "all 800ms",
										transitionDelay: isProfile
											? "0ms"
											: showResult
											? `${resultDelay + 2500}ms`
											: "0ms",
									}}
								/>
							</ThemeProvider>
							<ThemeProvider theme={lightStep2}>
								<Paper
									sx={{
										backgroundColor: "#ED5D8E",
										borderRadius: "0px",
										boxShadow: "inset 0 0 1px #00000088",
										maxWidth: "100%",
										height: 2,
										width: `${
											((props.state.players[props.userId].matchState?.hp ?? 0) /
												(props.state.matchState.maxHp ?? 0)) *
											100
										}%`,
										transition: isProfile ? "all 0ms" : "all 800ms",
										transitionDelay: isProfile
											? "0ms"
											: showResult
											? `${resultDelay + 2500}ms`
											: "0ms",
									}}
								/>
							</ThemeProvider>
						</Paper>

						{/* -------------- RESULT POPOUT --------------*/}
						<ThemeProvider theme={lightStep2}>
							<Card
								sx={{
									width: "180px",
									height: showResult ? "50px" : "0px",
									zIndex: 1,
									boxShadow: "inset 0 2px 5px #00000088",
									borderTopLeftRadius: "0px",
									borderTopRightRadius: "0px",
									transition: "all 500ms",
									transitionDelay: isProfile
										? "0ms"
										: showResult
										? `${resultDelay + 1400}ms`
										: props.step == MatchStep.RESULT
										? `${exitDelay + 400}ms`
										: `${exitDelay + 400}ms`,
								}}
							>
								<CardHeader
									sx={{
										padding: "5px",
										borderRadius: "0px",
									}}
									title={
										userDistance?.distance == -3
											? "No guess"
											: `${userDistance?.city.city} (${userDistance?.city.country})`
									}
									subheader={
										userDistance?.distance == -3
											? "Be quicker next time!"
											: userDistance?.distance == -2
											? "Correct City"
											: `${userDistance?.distance}km | -${userDistance?.diff}km`
									}
									titleTypographyProps={{
										marginTop: "0px",
										fontSize: "1.1rem",
										maxWidth: "170px",
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
									subheaderTypographyProps={{
										marginTop: "-5px",
										fontSize: "0.8rem",
									}}
								></CardHeader>
							</Card>
							<Card
								sx={{
									width: "180px",
									height: showResult ? "20px" : "0px",
									zIndex: 1,
									boxShadow: "inset 0 2px 5px #00000088",
									borderRadius: "0px",
									transition: "all 500ms",
									transitionDelay: isProfile
										? "0ms"
										: showResult
										? `${resultDelay + 2500}ms`
										: props.step == MatchStep.RESULT
										? `${exitDelay}ms`
										: `${exitDelay}ms`,
								}}
							>
								<CardHeader
									sx={{
										padding: "5px",
										backgroundColor: userDistance?.damage
											? "IndianRed"
											: "MediumSeaGreen",
									}}
									subheader={
										showResult
											? userDistance?.distance == -2
												? "Bullseye!"
												: userDistance?.damage
												? `-${userDistance?.damage} HP`
												: "Winner"
											: ""
									}
									subheaderTypographyProps={{
										marginTop: "-5px",
										fontSize: "0.9rem",
										color: "white",
									}}
								></CardHeader>
								<img
									src={partyPopper}
									style={{
										position: "absolute",
										width: "55px",
										right: 0,
										top: 0,
										transformOrigin: "15px 55px",
										transform:
											(showResult && userDistance?.distance) == -2
												? "scale(1.0, 1.0)"
												: "scale(0.0, 0.0)",
										marginRight: "-20px",
										marginTop: "120px",
										transition: "all 600ms",
										transitionDelay: isProfile
											? "0ms"
											: showResult
											? `${resultDelay + 2800}ms`
											: props.step == MatchStep.RESULT
											? `${exitDelay}ms`
											: `${exitDelay}ms`,
									}}
								></img>
								<img
									src={partyPopper}
									style={{
										position: "absolute",
										width: "55px",
										left: 0,
										top: 0,
										transformOrigin: "15px 55px",
										transform:
											(showResult && userDistance?.distance) == -2
												? "scale(-1.0, 1.0)"
												: "scale(0.0, 0.0)",
										marginLeft: "5px",
										marginTop: "120px",
										transition: "all 600ms",
										transitionDelay: isProfile
											? "0ms"
											: showResult
											? `${resultDelay + 2800}ms`
											: props.step == MatchStep.RESULT
											? `${exitDelay}ms`
											: `${exitDelay}ms`,
									}}
								></img>
							</Card>
							{/* -------------- PROFILE ELEMENTS --------------*/}
							{props.showPlayButton && (
								<ThemeProvider theme={lightTheme}>
									<Button
										variant="contained"
										onClick={props.onPlayPress}
										sx={{
											marginTop: loaded ? "-5px" : 0,
											paddingTop: loaded ? "10px" : 0,
											paddingBottom: loaded ? "5px" : 0,
											position: "absolute",
											width: "100%",
											height: loaded ? "40px" : 0,
											zIndex: -1,
											borderTopLeftRadius: 0,
											borderTopRightRadius: 0,
											minHeight: "0px",
											overflow: "hidden",
											transform: "translate(-50%, 0)",
											transition:
												"margin-top 800ms, padding-top 800ms, padding-bottom 800ms, height 800ms, background-color 200ms",
											transitionDelay: "1400ms, 1400ms, 1400ms, 1400ms, 0ms",
										}}
									>
										Play Now
									</Button>
								</ThemeProvider>
							)}

							<ThemeProvider theme={lightTheme}>
								<Paper
									sx={{
										width: "180px",
										height: showXp ? "20px" : 0,
										zIndex: 1,
										boxShadow: "inset 0 2px 5px #00000088",
										borderTopLeftRadius: 0,
										borderTopRightRadius: 0,
										transition: "all 500ms",
										transitionDelay: showResult
											? `${xpDelay}ms`
											: `${exitDelay}ms`,
										backgroundColor: shadeColor(colors[0], 90),
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										overflow: "hidden",
										position: "relative",
									}}
								>
									<Paper
										sx={{
											width: showXp
												? `${(props.profile.totalXp / 4000) * 100}%`
												: "0px",
											position: "absolute",
											left: 0,
											borderRadius: "0px",
											height: "30px",
											boxShadow: "inset 0 2px 5px #00000088",
											transition: "all 1000ms",
											transitionDelay: showResult
												? `${xpDelay + 200}ms`
												: `${exitDelay}ms`,
											backgroundColor: colors[0],
										}}
									></Paper>
									<Typography
										variant="subtitle2"
										color="white"
										sx={{
											zIndex: 1,
											textShadow: "0px 0px 10px #000000",
											marginTop: "2px",
										}}
									>{`${props.profile.totalXp} XP`}</Typography>
								</Paper>
							</ThemeProvider>

							<ThemeProvider theme={lightTheme}>
								<Paper
									sx={{
										width: "180px",
										height: "auto",
										zIndex: 1,
										boxShadow: "inset 0 2px 5px #00000088",
										borderTopLeftRadius: 0,
										borderTopRightRadius: 0,
										transition: "all 500ms",
										transitionDelay: "0ms",
										overflow: "hidden",
									}}
								>
									{props.profile.lastMatches?.map((match, index) => (
										<UserMatchLabel
											userId={props.userId}
											match={match}
											profile={props.profile}
											isProfile={isProfile}
											index={index}
											colors={colors}
										></UserMatchLabel>
									))}
								</Paper>
							</ThemeProvider>
						</ThemeProvider>
					</Card>
				</Badge>
			</Stack>
		</ThemeProvider>
	);
};

export const UserMatchLabel = (props: {
	userId: string;
	match: Match;
	profile: UserProfile;
	isProfile: boolean;
	index: number;
	colors: Array<string>;
}) => {
	let rank =
		props.match.result.ranking.findIndex(
			(user: User) => user.userId == props.userId
		) + 1;
	return (
		<Paper
			sx={{
				width: "100%",
				borderRadius: "0px",
				height: props.isProfile ? "30px" : "0px",
				overflow: "hidden",
				position: "relative",
				boxShadow: "inset 0 1px 2px #00000088",
				transition: "height 400ms, background-color 200ms",
				transitionDelay: props.isProfile
					? `${props.index * 100}ms, 0ms`
					: `${
							((props.profile.lastMatches?.length ?? 0) - props.index) * 100
					  }ms, 0ms`,
				":hover": {
					bgcolor: props.colors[0],
					color: "white",
				},
			}}
		>
			<Link
				to={`/match/${props.match.id}`}
				target="_blank"
				rel="noopener noreferrer"
			>
				<Button
					sx={{
						position: "absolute",
						width: "100%",
						height: "30px",
						left: 0,
						zIndex: 1000,
						":hover": {
							color: "white",
						},
					}}
				></Button>
			</Link>
			<Typography
				variant="subtitle2"
				color={
					rank == 1
						? "MediumSeaGreen"
						: rank == 2
						? "SlateGray"
						: rank == 3
						? "DarkGoldenRod"
						: "IndianRed"
				}
				sx={{
					margin: "5px",
					position: "absolute",
				}}
			>
				{`${getNumberWithOrdinal(rank)}`}
			</Typography>
			<Typography
				variant="caption"
				sx={{
					right: "5px",
					opacity: "0.5",
					margin: "6px",
					position: "absolute",
				}}
			>
				{dayjs(props.match.timestamp).fromNow()}
			</Typography>
		</Paper>
	);
};

function getNumberWithOrdinal(n: number) {
	var s = ["th", "st", "nd", "rd"],
		v = n % 100;
	return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

const StyledBadge = styled(Badge)(({ theme }) => ({
	"& .MuiBadge-badge": {
		backgroundColor: "#44b700",
		color: "#44b700",
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "ripple 1.2s infinite ease-in-out",
			border: "1px solid currentColor",
			content: '""',
		},
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1,
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0,
		},
	},
}));

function sortByUserId(a: string, b: string) {
	if (a < b) {
		return -1;
	}
	if (a > b) {
		return 1;
	}
	return 0;
}
