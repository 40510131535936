import { ThemeProvider } from "@emotion/react";
import { Card, CardHeader, Avatar, Badge, Paper } from "@mui/material";
import { useState, useEffect } from "react";
import Globe from "react-globe.gl";
import {
	User,
	EndResult,
	Arc,
	Label,
	stringToColor,
	useWindowDimensions,
	lightTheme,
	shadeColor,
} from "./App";
import globeTex from "./globe_texture_8k.png";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";

const markerSvg = `<svg viewBox="-4 0 36 36">
    <path fill="currentColor" d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"></path>
    <circle fill="white" cx="14" cy="14" r="7"></circle>
  </svg>`;

const fallbackAvatar =
	"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Angry%20Face.png";

export const ResultsGlobe = (props: {
	endResult: EndResult;
	players: { [userId: string]: User };
}) => {
	const { height, width } = useWindowDimensions();
	const [curHover, setCurHover] = useState(-2);
	const [curHoverOverride, setCurHoverOverride] = useState(-2);
	const [dummyState, setDummy] = useState(false);
	const [curPos, setCurPos] = useState<DOMRect | undefined>(undefined);
	const [positions, setPositions] = useState<{ [size: number]: DOMRect }>({});
	const [arcs, setArcs] = useState<Array<Arc>>([]);
	const [highlightedArc, setHighlightedArc] = useState(-2);
	const [addedArc, setAddedArc] = useState<Arc | undefined>(undefined);
	const [initialForceRender, setinitialForceRender] = useState(true);

	var lastHover = 0;
	//var curPos: DOMRect | undefined = undefined;

	interface LabelInfo {
		lat: number;
		lng: number;
	}

	interface GeoCoords {
		lat: number;
		lng: number;
		altitude: number;
	}

	function onZoom(pov: GeoCoords) {
		setCurHover(-2);
	}

	function setHoverArc(arc: Arc) {
		setAddedArc(arc);
		/*arcs.push(arc);
		setArcs(arcs);
		console.log(arcs);*/
	}

	function clearHoverArc() {
		setAddedArc(undefined);
		/*arcs.splice(0, arcs.length - 1);
		setArcs(arcs);
		console.log(arcs);*/
	}

	useEffect(() => {
		// Update the document title using the browser API
		console.log("GLOBE X");
		generateArcs(initialForceRender);
		if (initialForceRender) {
			setinitialForceRender(false);
		}
	});

	const curHoverFinal = curHoverOverride != -2 ? curHoverOverride : curHover;
	console.log(
		"FINAL: ",
		curHoverFinal,
		", OVERRIDE: ",
		curHoverOverride,
		", HOVER: ",
		curHover
	);

	const labels: Array<Label> = [];
	function generateArcs(force = false) {
		const genArcs: Array<Arc> = [];
		var forceUpdateArcs = force;
		props.endResult.rounds.forEach((round, index) => {
			labels.push({
				lat: round.city?.lat ?? 0,
				lng: round.city?.lng ?? 0,
				size: index,
				color: ["#e91e63"],
				round: round,
			});

			genArcs.push({
				startLat: round.city?.lat ?? 0,
				startLng: round.city?.lng ?? 0,
				endLat: round.distances[0].city?.lat ?? 0,
				endLng: round.distances[0].city?.lng ?? 0,
				color:
					index == curHoverFinal
						? ["MediumSeaGreen", "MediumSeaGreen"]
						: ["#e91e63", "#e91e63"],
			});
			if (index == curHoverFinal && highlightedArc != index) {
				console.log(index, curHoverFinal, highlightedArc);
				setHighlightedArc(index);
				forceUpdateArcs = true;
			} else if (curHoverFinal == -2 && highlightedArc != -2) {
				setHighlightedArc(-2);
				forceUpdateArcs = true;
			}

			if (index == props.endResult.rounds.length - 1) {
				labels.push({
					lat: round.distances[0].city?.lat ?? 0,
					lng: round.distances[0].city?.lng ?? 0,
					size: -1,
					color: ["#e91e63"],
					round: round,
				});
			}
		});
		if (addedArc) {
			genArcs.push(addedArc);
			if (
				arcs[arcs.length - 1].endLat != genArcs[genArcs.length - 1].endLat ||
				arcs[arcs.length - 1].endLng != genArcs[genArcs.length - 1].endLng
			) {
				forceUpdateArcs = true;
			}
		}
		console.log("ARCS: ", arcs.length);

		if (genArcs.length != arcs.length || forceUpdateArcs) {
			forceUpdateArcs = false;
			setArcs(genArcs);
		}
	}

	generateArcs();

	return (
		<div style={{}}>
			<Globe
				width={width}
				height={height}
				globeImageUrl={globeTex}
				arcsData={arcs}
				arcColor={"color"}
				arcAltitudeAutoScale={0.4}
				arcStroke={1.0}
				arcDashLength={() => 0.55}
				arcDashGap={() => 0.01}
				arcDashAnimateTime={() => 5000}
				arcsTransitionDuration={0}
				backgroundColor={"#00000000"}
				onZoom={onZoom}
				htmlTransitionDuration={2.0}
				htmlElementsData={labels}
				htmlElement={(d: any) => {
					const el = document.createElement("div");
					console.log(d);
					/*el.innerHTML = renderToString(
						<GlobeLabel
							label={d}
							index={d.size}
							isHover={curHover}
						></GlobeLabel>
					);*/
					el.innerHTML = markerSvg;
					el.style.color = "#e91e63";
					el.style.width = `25px`;
					el.style.marginTop = "-12.5px";

					el.style.cursor = "pointer";
					el.style.pointerEvents = "auto";
					el.style.zIndex = "0";
					el.style.transition = "opacity 200ms";
					/*el.onblur = () => {
						positions[d.size] = el.getBoundingClientRect();
						setPositions(positions);
						setDummy(!dummyState);
						console.log(positions);
					};*/

					el.onmouseover = () => {
						setCurPos(el.getBoundingClientRect());
						console.log("GLOBE MOUSEOVER: ", curPos);
						setCurHover(d.size);
					};
					el.onmouseleave = () => {
						//lastHover = curHover;
						setCurHover(-2);
					};
					return el;
				}}
			/>
			{labels.map((label, index) => (
				<GlobeLabel
					label={label}
					index={label.size}
					isHover={curHoverFinal}
					pos={curPos}
					players={props.players}
					onMouseOver={setCurHoverOverride}
					onMouseLeave={setCurHover}
					setHoverArc={setHoverArc}
					clearHoverArc={clearHoverArc}
				></GlobeLabel>
			))}
		</div>
	);
};

const GlobeLabel = (props: {
	label: Label;
	isHover: number;
	index: number;
	pos?: DOMRect;
	players: { [userId: string]: User };
	onMouseOver: Function;
	onMouseLeave: Function;
	setHoverArc: Function;
	clearHoverArc: Function;
}) => {
	const { height, width } = useWindowDimensions();
	const [isClicked, setIsClicked] = useState(false);
	const [isMouseOver, setIsMouseOver] = useState(false);
	const [hoverItem, setHoverItem] = useState(-2);

	//console.log("LABEL RENDER");

	useEffect(() => {
		// Update the document title using the browser API
		//console.log("LABEL ", props.label.round.city?.city, isClicked, isMouseOver);
		if (props.isHover != props.index) {
			setIsClicked(false);
			setHoverItem(-2);
		}
		if (!isMouseOver) {
			return;
			setIsClicked(false);
		} else {
			return;
			props.onMouseOver(props.label.size);
		}
	});

	return (
		<div
			id={props.label.round.city?.city}
			style={{
				position: "absolute",
				left: props.isHover == props.index ? props.pos?.left : -1000,
				top: props.isHover == props.index ? props.pos?.top : -1000,
				marginTop: "25px",
				transition: "opacity 500ms",
				pointerEvents: "all",
				cursor: "pointer",
				color: "#e91e63",
				width: "25px",
				backgroundColor: "black",
				zIndex: 1000,
				opacity: props.isHover == props.index ? "1.0" : "0.0",
			}}
			onClick={() => {
				if (props.label.size != -1) {
					setIsClicked(true);
				}
			}}
			onMouseOver={() => {
				setIsMouseOver(true);
				console.log("ONMOUSEOVER: ", props.label.round.city?.city);
			}}
			onMouseLeave={() => {
				setIsMouseOver(false);
				if (props.isHover == props.index) {
					props.onMouseOver(-2);
				}
				console.log("ONMOUSELEAVE: ", props.index);
			}}
			onMouseEnter={() => {
				setIsMouseOver(false);
				if (props.isHover == props.index) {
					props.onMouseOver(props.index);
				}
				console.log("ONMOUSEENTER: ", props.label.round.city?.city);
			}}
			onMouseOut={() => {
				setIsMouseOver(false);
				console.log("ONMOUSEOUT: ", props.label.round.city?.city);
			}}

			//onMouseLeave={props.onMouseLeave(0)}
		>
			<ThemeProvider theme={lightTheme}>
				<Card
					style={{
						position: "absolute",
						top: "-30px",

						left: "50%",
						pointerEvents: "all",
						backgroundColor: "white",
						borderRadius: "50px",
						padding: "10px",
						width: "35px",
						height: "35px",
						zIndex: 103,
						boxShadow:
							"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",

						transform: "translate(-50%, 0)",
						textAlign: "center",

						opacity: props.isHover == props.index ? "1.0" : "0.0",
					}}
				></Card>
				<svg
					viewBox="-4 0 36 36"
					style={{
						position: "absolute",
						top: "-25px",
						left: "50%",
						width: "25px",
						transform: "translate(-50%, 0)",
						zIndex: 105,
						pointerEvents: "all",
					}}
				>
					<path
						fill="currentColor"
						d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"
					></path>
					<circle fill="white" cx="14" cy="14" r="7"></circle>
				</svg>
				<Card
					style={{
						position: "absolute",
						top: props.isHover == props.index ? "-5px" : "-10px",
						transition: "top 400ms, height 400ms",
						left: "-50px",
						pointerEvents: "all",
						backgroundColor: "white",
						borderRadius: "5px",
						paddingTop: "10px",
						width: "120px",
						height: isClicked ? "45px" : "35px",
						zIndex: 102,
						color: "black",
						fontWeight: "bold",
						boxShadow:
							"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",

						textAlign: "center",

						opacity: props.isHover == props.index ? "1.0" : "0.0",
					}}
				>
					<CardHeader
						style={{
							marginTop: "-20px",
							width: "120px",
							justifyContent: "center",
						}}
						titleTypographyProps={{
							sx: {
								fontSize: "1.1rem",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
								overflow: "hidden",
								maxWidth: "120px",
							},
						}}
						title={
							props.label.size > -1
								? props.label.round.city?.city
								: props.label.round.distances[0].city?.city
						}
						subheader={
							isClicked ? props.label.round.distances[0].city?.city : ""
						}
						subheaderTypographyProps={{
							sx: {
								fontSize: "0.8rem",
								marginTop: isClicked ? "-8px" : "8px",
								transition: "margin-top 300ms",
							},
						}}
					/>
				</Card>
				{props.label.size != -1 && (
					<Card
						style={{
							position: "absolute",
							top:
								(props.isHover == props.index ? 20 : -5) +
								(isClicked ? 10 : 0) +
								"px",
							transition: `all ${hoverItem == -2 || !isClicked ? 700 : 200}ms`,
							left: "-50px",
							pointerEvents: "all",
							backgroundColor: isClicked ? "MediumSeaGreen" : "#e91e63",
							borderRadius: "5px",
							paddingTop: "17px",
							width: "120px",
							height: hoverItem == -1 && isClicked ? "55px" : "35px",
							zIndex: 101,
							boxShadow:
								"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",

							textAlign: "center",

							opacity: props.isHover == props.index ? "1.0" : "0.0",
						}}
						onMouseOver={() => {
							if (isClicked) {
								setHoverItem(-1);
								let arc: Arc = {
									startLat: props.label.round.city?.lat ?? 0,
									startLng: props.label.round.city?.lng ?? 0,
									endLat: props.label.round.targetCity?.lat ?? 0,
									endLng: props.label.round.targetCity?.lng ?? 0,
									color: ["#E73873", "#E73873"],
								};
								props.setHoverArc(arc);
							}
						}}
						onMouseLeave={() => {
							setHoverItem(-3);
							props.clearHoverArc();
						}}
					>
						<CardHeader
							style={{
								marginTop: "-20px",
								fontSize: "12px",
								fontWeight: "bold",
							}}
							title={
								isClicked ? `${props.label.round.target}km` : "Click for more"
							}
							titleTypographyProps={{
								color: "white",
								sx: {
									transition: "all 200ms",

									position: "absolute",
									left: isClicked ? "30px" : "-15px",
									top: "14px",
									width: isClicked ? "85px" : "120px",
									textAlign: "right",
								},
							}}
							subheader={props.label.round.targetCity?.city}
							subheaderTypographyProps={{
								sx: {
									position: "absolute",
									left: "30px",
									top: "33px",
									width: "85px",
									textAlign: "right",
									color: "#EAEAEA",
								},
							}}
							avatar={
								<ModeStandbyIcon
									sx={{
										width: "20px",
										height: "20px",
										marginLeft: isClicked ? "-7px" : "-50px",
										color: "white",
										transition: "all 200ms",
									}}
								></ModeStandbyIcon>
							}
						/>
					</Card>
				)}
				{props.label.size != -1 &&
					props.label.round.distances.map(
						(distance, index) =>
							distance.distance != -1 && (
								<Card
									style={{
										position: "absolute",
										top: isClicked
											? `${
													(hoverItem < index && hoverItem >= -1 ? 20 : 0) +
													30 +
													(index + 1) * 25
											  }px`
											: `${-5}px`,
										transition: `top ${
											hoverItem == -2 ? 700 : 200
										}ms, height 200ms, background-color 200ms`,
										transitionDelay:
											hoverItem == -2 ? `${index * 200}ms` : "0ms",
										left: "-50px",
										pointerEvents: "all",
										backgroundColor: hoverItem == index ? "#F7F7F7" : "white",
										borderRadius: "5px",
										paddingTop: "17px",
										width: "120px",
										height: hoverItem == index ? "55px" : "35px",
										zIndex: 100 - index,
										boxShadow:
											"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",

										textAlign: "center",

										opacity: isClicked ? "1.0" : "0.0",
									}}
									onMouseOver={() => {
										setHoverItem(index);
										let arc: Arc = {
											startLat: props.label.round.city?.lat ?? 0,
											startLng: props.label.round.city?.lng ?? 0,
											endLat: distance.city?.lat ?? props.label.round.city?.lat,
											endLng: distance.city?.lng ?? props.label.round.city?.lng,
											color: ["#E73873", "#E73873"],
										};
										props.setHoverArc(arc);
									}}
									onMouseLeave={() => {
										setHoverItem(-3);
										props.clearHoverArc();
									}}
								>
									<CardHeader
										sx={{
											marginTop: "-20px",
											fontSize: "12px",
											fontWeight: "bold",
										}}
										/*title={`${
									hoverItem == index
										? (distance.distance - props.label.round.target >= 0
												? "+"
												: "") +
										  (distance.distance - props.label.round.target).toFixed(2)
										: distance.distance
								}km`}*/
										title={`${
											distance.distance == -2 ? 0 : distance.distance
										}km`}
										titleTypographyProps={{
											color:
												hoverItem == index
													? "white"
													: index == 0
													? "#e91e63"
													: "black",
											sx: {
												position: "absolute",
												left: "30px",
												top: "14px",
												width: "85px",
												textAlign: "right",
												transition: "all 200ms",
											},
										}}
										subheader={distance.city?.city}
										subheaderTypographyProps={{
											color: "Gainsboro",
											sx: {
												position: "absolute",
												left: "30px",
												top: "33px",
												width: "85px",
												textAlign: "right",
											},
										}}
										avatar={
											<Badge
												overlap="circular"
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "right",
												}}
												sx={{
													marginTop: hoverItem == index ? "20px" : "10px",
													transition: "all 200ms",
												}}
												variant="dot"
											>
												<Paper
													sx={{
														bgcolor: shadeColor(
															stringToColor(
																props.players?.[distance.userId]?.profile
																	.username ?? "Markus"
															),
															-20
														),
														borderRadius: "50%",
														padding: "22px",
														paddingLeft: "37px",
														paddingRight: "7px",
														position: "absolute",
														width: hoverItem == index ? "160px" : "51px",
														height: hoverItem == index ? "160px" : "51px",
														transform:
															hoverItem == index
																? "translate(-42px, -50%)"
																: "translate(-32px, -50%)",
														boxShadow: "inset 0 0 2px #00000088",
														zIndex: 0,
														transition: "all 200ms",
														transitionDelay: "0ms",
													}}
												/>
												<Paper
													sx={{
														bgcolor: shadeColor(
															stringToColor(
																props.players?.[distance.userId]?.profile
																	.username ?? "Markus"
															),
															-10
														),
														borderRadius: "50%",
														padding: "22px",
														paddingLeft: "37px",
														paddingRight: "7px",
														position: "absolute",
														width: hoverItem == index ? "100px" : "48px",
														height: hoverItem == index ? "100px" : "48px",
														transform:
															hoverItem == index
																? "translate(-42px, -50%)"
																: "translate(-32px, -50%)",
														boxShadow: "inset 0 0 2px #00000088",
														zIndex: 0,
														transition: "all 200ms",
														transitionDelay: "0ms",
													}}
												/>
												<Avatar
													alt={
														props.players?.[distance.userId]?.profile.username
													}
													src={
														props.players?.[distance.userId]?.profile
															.avatarUrl ?? fallbackAvatar
													}
													sx={{
														bgcolor: stringToColor(
															props.players?.[distance.userId]?.profile
																.username ?? "Markus"
														),
														padding: hoverItem == index ? "17px" : "11px",
														paddingLeft: hoverItem == index ? "17px" : "18px",
														paddingRight: hoverItem == index ? "17px" : "4px",
														position: "absolute",
														width: hoverItem == index ? "75px" : "45px",
														height: hoverItem == index ? "75px" : "45px",
														transform:
															hoverItem == index
																? "translate(-32px, -50%)"
																: "translate(-32px, -50%)",
														boxShadow: "inset 0 0 2px #00000088",
														zIndex: 0,
														transition: "all 200ms",
														transitionDelay: "0ms",
													}}
												/>
											</Badge>
										}
									/>
								</Card>
							)
					)}
			</ThemeProvider>
		</div>
	);
};
